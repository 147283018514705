import React from "react";
import stars from "../../images/images/stars.svg";

export default function BannerContainer(props) {
  const { title, heading, caption } = props;

  return (
    <div className="p-4 flex flex-col md:flex-row md:items-center">
      <div className="md:pl-24 flex-1 relative">
        <span
          className="text-[#57B847] font-semibold text-xl"
          dangerouslySetInnerHTML={{ __html: title }}
        ></span>
        <img
          src={stars}
          alt="stars"
          className="absolute right-0 top-0 md:hidden"
        />
        <div className="pt-4 md:pt-12  z-10 w-full">
          <div
            className="title text-2xl md:text-6xl leading-tight"
            dangerouslySetInnerHTML={{ __html: heading }}
          ></div>
        </div>
      </div>
      <div className="flex-1 relative flex md:justify-end">
        <img
          src={stars}
          alt="stars"
          className="hidden md:block mb-2 pr-24 -z-10"
        />
        <div
          className="md:absolute mt-6 md:bottom-0 mr-24 md:mr-0 md:right-56"
          dangerouslySetInnerHTML={{ __html: caption }}
        ></div>
      </div>
    </div>
  );
}

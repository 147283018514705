import React from "react";
import AnalyticsBanner from "../components/analytics_as_a_service/AnalyticsBanner";
import Footer from "../components/Footer";
import GetAQuote from "../components/home/GetAQuote";
import WhoWeAre from "../components/home/WhoWeAre";
import TopbarDark from "../components/TopbarDark";
import HowDoesThinklokalHelpYou from "../components/what_we_do/HowDoesThinklokalHelpYou";
import Results from "../components/what_we_do/Results";

export default function AnalyticsAsService() {
  return (
    <main>
      <TopbarDark />
      <AnalyticsBanner />
      <HowDoesThinklokalHelpYou />
      <Results />
      <WhoWeAre />
      <GetAQuote />
      <Footer />
    </main>
  );
}

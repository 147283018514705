import React from "react";
import BannerContainer from "../common/BannerContainer";
import { StaticImage } from "gatsby-plugin-image";
import IMAGES from "../../utils/images";
import AniLink from "gatsby-plugin-transition-link/AniLink";

export default function AnalyticsBanner() {
  return (
    <div>
      <BannerContainer
        title="services"
        heading='Analytics as a Service <br /> <span style="font-size:36px;font-weight:100">Let us handle this complex area for you</span><span style="color:#57B847">.</span>'
        caption='A partnership-centric <br className="hidden md:block" />approach to all aspects<br className="hidden md:block" />of data analytics.'
      />
      <div
        id="analytics-bg "
        className="mt-12 flex justify-end max-h-[600px] object-left-top"
      >
        <StaticImage
          src=" ../../../../images/images/successful-asian-young-businessman-working-with-hand-typing-keyboard-laptop-computer-tablet-with-blank-touch-screen-isolated-pen-notebook-white-wooden.jpg"
          className="object-fill"
          alt
        />
      </div>

      <section>
        <div className="py-16 px-6 md:px-24">
          <span className="text-[#57B847] font-semibold text-2xl">
            Analytics as a Service
          </span>
          <p className="py-8 leading-loose text-2xl">
            Data Analytics across all operations is essential across Marketing,
            Sales, Operations, Finance, Logistics, Customer Support...
            practically every department can and should leverage data analytics
            for operational effeciency, growth, cost optimization, stakeholder
            delight and great ROI. <br /> <br /> However this area, by its very
            nature, is extremely challenging. Most companies who have embarked
            on this journey by building an in-house team do not reach the value
            realization stage. <br /> <br />
            Consultants, technology platforms, cloud service providers are often
            far removed from the realities organizations face in navigating
            complex contracts, licences, technologies and the ever-present hype,
            leave alone the challenge of staffing, implementation and
            deployment. <br /> <br /> This is where we come in - offering to
            take away all these for a fixed per-month cost and clear
            deliverables.
          </p>
          <div className="flex">
            <AniLink fade to="/who_we_are">
              <button className="rounded-full bg-[#57B847] px-12 py-2 text-white">
                Who we are
              </button>
            </AniLink>
          </div>
        </div>
      </section>
    </div>
  );
}

import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Collapse } from "@mui/material";
import AniLink from "gatsby-plugin-transition-link/AniLink";

let pointsList = [
  {
    id: 1,
    name: "Business Imperatives",
    Description:
      "So what areas do you need to improve by leveraging data analytics? Which ones are low-hanging fruit, that can score easier wins? What are the critical areas that need priority? Who are the internal champtions and sponsors - do they have time and resources to set aside for this?. Are there budgets avaialable?",
  },
  {
    id: 2,
    name: "Data Sources, Modelling",
    Description:
      "What data and data sources are available? What is the data quality? Do we need to purchase data from third parties? Do we need to capture data better? What data models can be created, and what can we expect from these data models in the form of analytics?",
  },
  {
    id: 3,
    name: "Extract, Load, Transform",
    Description:
      "The hard, maybe the hardest, part. What kind of inter-department data access permissions are needed? Who 'owns' the data? What licences do you already have for data extraction, storage and analysis? What kind of data security policies and compliances need to be followed? What is your current state and maturity in ETL/ELT?",
  },
  {
    id: 4,
    name: "Initial reports, dashboards, user training",
    Description:
      "Generally undertaken as part of the Pilot. What reports, dashboards and ad-hoc user reporting will be delivered. Get user training organized, delivered and evaluated. Make changes based on user feedback. Get a sense of the engagement and way forward.",
  },
  {
    id: 5,
    name: "Reporting",
    Description:
      "Reporting on the engagement itself (meta data). Usage, issue tracking, requirements, authorised users, and more.",
  },
  {
    id: 6,
    name: "Issues and Changes",
    Description:
      "The area that cause most inhouse data analytics to fail, and a core value proposition from ThinkLokal. The management of changes, additions, issues - discussions, modelling, sampling, developing, validating and deployment - all in a never ending process of change.",
  },
  {
    id: 7,
    name: "New Requirements",
    Description:
      "As SBU's start seeing the value of Data Driven Decisions, we've seen new requirement come in frequently. Typically each of these become a project in itself - taking months to bring to fruition. With ThinkLokal we manage each one as a rapid sub-project, delivering in days and weeks, not months, and likely within our fixed-fee contract.",
  },
  {
    id: 8,
    name: "New Data Sources",
    Description:
      "Over time existing and new business functions generate new data, or capture data better. Third-part data brokers aften make a compelling case to license external data sources. Incorporating these into existing data models, reports, dashboards as well as new ones is an integral part of ongoing Data Analytics.",
  },
];

export default function HowDoesThinklokalHelpYou() {
  const [selectedPoint, setSelectedPoint] = useState(pointsList[0]);

  const handlePointClick = (data) => {
    setSelectedPoint(data);
  };

  const SubSection = () => {
    return (
      <div className="flex flex-col flex-1 md:pl-24 py-8">
        <div>
          <StaticImage
            src="https://firebasestorage.googleapis.com/v0/b/admin-upload-6e7b6.appspot.com/o/Thinklokal-website%2Fimages%2Fbusiness-analyse-concept-idea-background-min.jpg?alt=media&token=fd3a6ca0-9f19-4418-b829-f77dddbe362f"
            alt="img"
          />
        </div>
        <div className>
          <div className="mt-6 text-2xl font-semibold">
            {selectedPoint.name}
          </div>
          <div className="mt-4 max-w-4xl">{selectedPoint.Description}</div>
          <div className="mt-8">
            <AniLink
              fade
              to="/contact_us"
              className="rounded-full bg-[#57B847] px-12 py-3 text-white"
            >
              Contact us for demo
            </AniLink>
          </div>
        </div>
      </div>
    );
  };

  return (
    <section className="bg-[#F9F9F9] py-16 px-6 md:px-24">
      <div className="flex flex-col md:flex-row">
        <div className="flex-[0.5]">
          <div className="text-5xl py-4">The Data Analytics Process</div>
          <div className="py-4">
            {pointsList.map((point) => (
              <div>
                <div
                  className={`flex items-center text-2xl  font-semibold py-2 cursor-pointer ${
                    selectedPoint.id === point.id
                      ? "text-black"
                      : "text-[#A4ADAE]"
                  }`}
                  onClick={() => handlePointClick(point)}
                >
                  <div
                    className={`${
                      selectedPoint.id === point.id
                        ? "text-green-500"
                        : "text-[#A4ADAE]"
                    } text-4xl pr-6`}
                  >
                    |
                  </div>
                  <div> {point.name} </div>
                </div>
                <Collapse in={point.id === selectedPoint.id} collapsedSize={0}>
                  <div className="block md:hidden">
                    <SubSection />
                  </div>
                </Collapse>
              </div>
            ))}
          </div>
        </div>
        <div className="flex-1 hidden md:block">
          <SubSection />
        </div>
      </div>
    </section>
  );
}

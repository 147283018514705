import React, { useState } from "react";
import ICONS from "../../utils/icons";
import { Collapse } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function Results() {
  const [selectedItem, setSelectedItem] = useState({});
  const resultsList = [
    {
      id: 1,
      name: "We are very small and don't need analytics",
      summary: "Not really. Think again.",
      description: "Every company, however small, needs analytics to make data driven decisions, understand hidden patterns in their data and make sense of unforeseen circumstances. Businesses which don't rely on analytics will need to constantly struggle and keep reacting to moves by competition.",
    },
    {
      id: 2,
      name: "We don't know where to start",
      summary: "Start with us - we are a very low-risk company.",
      description: "We have done this before and will do it again. We work with you to understand your business, goals and targets and help you start off with affordable options. Small, quick wins are stepping stones to success in any analytics journey.",
    },
    {
      id: 3,
      name: "We only need small, fixed number of reports",
      summary: "Highly unlikely, and probably a mistake to think that.",
      description: "If you can't analyze it, you can't manage it. A fixed number of reports and dashboards are a good start, but as you reap the benefits of the initial set, you will soon ask for more. Analytics, like your business, is a journey, not a destination.",
    },
    {
      id: 4,
      name: "We have already invested in licenses",
      summary: "License costs are a fraction of TCO.",
      description: "Return on investment and total cost of ownership are the most important metrics for any technology initiative you undertake. We will help you understand the implications of these metrics and eventually transition to the most affordable plans for your requirements.",
    },
    {
      id: 5,
      name: "We have issues with our data quality",
      summary: "Who does not?",
      description: "While its always better to correct data quality issue at the source, this is often impractical in systems that are already operational. Having said that, our analytics can also be used to identify data quality issues and lay out the framework for improving data governance over time.",
    },
    {
      id: 6,
      name: "We have analytics in place but the adoption is slow. What will change?",
      summary: "Its a matter of 'how' we deliver, not just 'what'.",
      description: "The key to success in analytics is being successful across Availability (meaning timeliness, multi-device support, alerts, etc.), Confidentiality (meaning data integrity, data encryption, data retention, etc.), and Agility (meaning rapid response to needs, ad-hoc reporting, access to immediate consulting and more)."
    },
    {
      id: 7,
      name: "Our business is complex and our requirements constantly change",
      summary: "Complexity and change are a given today. We are here to help.",
      description: "Agile development is crucial in addressing ever changing needs. When the global business landscape is volatile, uncertain, complex and ambiguous, you cannot expect your requirements to be static. That's where we  help you be nimble and agile across all your data analytics requirements."
    },
    {
      id: 8,
      name: "The Data Analytics landscape is confusing and we have no clue where to start",
      summary: "Like most complex things, expertise makes things easier.",
      description: "Analytics technologies are evolving rapidly and you can get lost in the stream of technologies and jargons that are invented every day. We help insulate you from all the noise out there, helping you focus on your core business objectives, instead of navigating the data analytics landscape."
    },
    {
      id: 9,
      name: "We already have an in-house analytics team",
      summary: "Even better. We will turn them into data champions.",
      description: "We are not a replacement to any in-house team. No one understands your business and data better than you. However, our expertise will help you turn your in-house team into real data champions for your business users and their expertise will be used in value added functions rather than administrative functions."
    },
    {
      id: 10,
      name: "We have problem in finding the right analytics talent",
      summary: ".. and the situation is not going to improve.",
      description: "We are in the business of analytics and you are in the business of what you do. We invest a lot of time, energy, money and effort in hiring and retaining the right talent and you can leverage on these investments. Treat us as your backend analytics team and our team becomes yours. "
    },
  ];

  const handleItemClick = (result) => {
    if (result.id === selectedItem.id) {
      setSelectedItem({});
    } else {
      setSelectedItem(result);
    }
  };
  return (
    <div>
      <div className="py-16 px-6 md:px-24">
        <span className="text-[#57B847] font-semibold text-2xl">
          Frequently Asked Questions
        </span>
        <div className="pt-6 md:pt-12">
          <div className="title text-4xl md:text-6xl md:leading-tight">
            Are we right for you? 
          </div>
          <div className="pt-12">
            {resultsList.map((result) => (
              <>
                <div
                  className="flex items-center py-6 cursor-pointer"
                  onClick={() => handleItemClick(result)}
                >
                  <div className="flex justify-between w-full">
                    <img
                      src={ICONS.CheckedIcon}
                      alt
                      className="text-[#57B847] pr-6"
                    />
                    <span className="flex-1 pr-4">
                      {result.name}
                    </span>
                  </div>
                  <img src={ICONS.PlusIcon} alt className="content-end" />
                </div>
                <Collapse in={selectedItem.id === result.id} collapsedSize={0}>
                  <div className="px-12 pt-2 pb-6 -mt-2">
                    <b>{result.summary}</b>
                    <p className="mt-2">
                      {result.description}
                    </p>
                  </div>
                </Collapse>
                <hr />
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
